var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OwnerConsultForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            { attrs: { span: 24 } },
            [
              _c("col2-block", { attrs: { title: "聊天信息" } }, [
                _c(
                  "div",
                  { staticClass: "info" },
                  [
                    _vm._l(_vm.allMessages, function (data, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: { position: "relative", width: "100%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              class:
                                _vm.sendUser === data.sendUserId
                                  ? "contentsLeft"
                                  : "contentsRight",
                            },
                            [
                              _c("div", { staticClass: "contents" }, [
                                _c("div", { staticClass: "img" }, [
                                  _c("img", {
                                    attrs: {
                                      src: data.sendUserHeadPath,
                                      alt: "",
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "datas" }, [
                                  _c("div", { staticClass: "sendNameData" }, [
                                    _c("p", { staticClass: "sendUserName" }, [
                                      _vm._v(_vm._s(data.sendUserName)),
                                    ]),
                                    _c("div", { staticClass: "time" }, [
                                      _vm._v(_vm._s(data.updateTime)),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "content" }, [
                                    _c("div", { staticClass: "data" }, [
                                      _vm._v(_vm._s(data.content)),
                                    ]),
                                    _c("div", { staticClass: "backImg" }),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm.isShow
                      ? _c(
                          "div",
                          {
                            staticClass: "loadMore",
                            on: { click: _vm.loadMore },
                          },
                          [_vm._v("点击加载更多")]
                        )
                      : _vm._e(),
                    _vm.isShowSendMsg
                      ? _c("div", { staticClass: "sendMsg" }, [
                          _c(
                            "div",
                            [
                              _c("v-textarea", {
                                staticClass: "sendTextArea",
                                attrs: {
                                  placeholder: "请输入回复内容...",
                                  maxlength: 150,
                                  showCounter: false,
                                  rows: 1,
                                },
                                model: {
                                  value: _vm.form.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "content", $$v)
                                  },
                                  expression: "form.content",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "sendButton" },
                            [
                              _c("v-button", {
                                attrs: { type: "success", text: "发送" },
                                on: { click: _vm.sendMsg },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }