// 获取列表
const getListURL = `${API_CONFIG.shopBaseURL}volunteer/help/list`

// 编辑
const editURL = `${API_CONFIG.baseURL}serverOwnerConsultAction!reply.action`
// 查询详情
const queryURL = `${API_CONFIG.baseURL}serverOwnerConsultAction!queryPageOne.action`

export {
  getListURL,
  editURL,
  queryURL
}
