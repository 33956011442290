<template>
  <div class="OwnerConsultForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail :span="24">
        <col2-block title="聊天信息">
        <div class="info">
          <div style="position: relative;width: 100%;" v-for="(data, index) in allMessages" :key="index">
            <div :class='sendUser === data.sendUserId ? "contentsLeft" : "contentsRight"'>
              <div class="contents">
              <div class="img">
                <img :src="data.sendUserHeadPath" alt="">
              </div>
              <div class="datas">
                <div class="sendNameData">
                  <p class="sendUserName">{{data.sendUserName}}</p>
                  <div class="time">{{data.updateTime}}</div>
                </div>
                <div class="content">
                  <div class="data">{{data.content}}</div>
                  <div class="backImg"></div>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div v-if="isShow" class="loadMore" @click="loadMore">点击加载更多</div>
          <div class="sendMsg" v-if="isShowSendMsg">
            <div>
              <v-textarea class="sendTextArea" v-model="form.content" placeholder="请输入回复内容..." :maxlength="150" :showCounter="false" :rows="1"></v-textarea>
            </div>
            <div class="sendButton">
              <v-button type="success" text="发送" @click="sendMsg"></v-button>
            </div>
          </div>
        </div>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { queryURL, editURL } from './api'
import { Col2Detail, Col2Block } from 'components/bussiness'

export default {
  name: 'OwnerConsultForm',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      isShowSendMsg: false,
      isShow: false,
      width: 182,
      allMessages: [],
      query: this.$route.query,
      form: {
        content: ''
      },
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: ''
      },
      msgReason: Number,
      msgId: '',
      userId: '',
      reciveUserId: '',
      sendUserName: '',
      sendUser: '',
      postHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }
    }
  },
  created () {
    const type = Number(this.$route.query.type)
    this.isShowSendMsg = type === 1
    console.log(this.isShowSendMsg)
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('查看详情')
      this.$refs.formPanel.getData({ id })
    }
  },
  methods: {
    loadMore () {
      let data = {
        id: this.$route.query.id,
        startRow: 1
      }
      this.$axios.post(queryURL, data).then(res => {
        if (res.status === '100') {
          let data = res.data
          this.isShow = false
          data.allMessages.forEach(item => {
            this.allMessages.push(item)
          })
        }
      })
    },
    update (data) {
      this.allMessages = data.allMessages
      this.isShow = this.allMessages.length === 20
      this.msgId = data.one.msgId
      this.msgReason = data.one.msgReason
      this.userId = data.one.sendUserId
      this.reciveUserId = data.one.userId
      this.sendUserName = data.one.sendUserName
      this.sendUser = data.one.sendUserId
    },
    sendMsg () {
      if (!this.form.content.trim()) {
        this.$message('回复内容不能为空')
        return false
      }
      let data = {
        msgId: this.msgId,
        content: this.form.content,
        userId: this.userId,
        reciveUserId: this.reciveUserId,
        msgReason: this.msgReason ? this.msgReason : 0,
        dataObject: this.sendUserName
      }
      this.$axios.post(editURL, data, this.postHeader).then(res => {
        if (res.status === '100') {
          this.$router.go(-1)
        }
      })
    },
    submitBefore (data) {
      return true
    }
  }
}
</script>
<style scoped lang="scss">
.info {
  margin-left: auto;
  margin-right: auto;
  width: 879px;
  margin-top: 30px;
  .contentsLeft {
    float: left;
    width: 100%;
    .contents {
      display: flex;
      margin: 10px;
      .img {
        display: inline-block;
        height: 60px;
        margin: 5px 10px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
      .sendUserName {
        font-size: 16px;
        margin: 0 20px;
      }
      .content {
        position: relative;
        display: inline-block;
        min-width: 30px;
        max-width: 682px;
        word-break: break-all;
        word-wrap: break-word;
        min-height: 22px;
        background: #2a92c6;
        border-radius: 15px;
        margin-bottom: 20px;
        padding: 6px 8px;
        color: #FDFAFA;
        margin-top: -5px;
        .backImg {
          position: absolute;
          top: 7px;
          left: -4px;
          background: url(./img/corner.png) no-repeat;
          height: 8px;
          width: 6px;
        }
        .data {
          position: relative;
          padding: 0 5px;
        }
      }
      .sendNameData {
        display: flex;
        margin-bottom: 8px;
         .time {
          padding: 0 100px;
          color: #D8D8D8;
          font-family: '微软雅黑';
          font-size: 12px;
        }
      }
    }
  }
  .contentsRight {
    float: right;
    width: 100%;
    .contents {
      margin: 10px;
      .img {
        float: right;
        height: 60px;
        margin: 5px 10px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
      .datas {
        float: right;
        .content {
          clear: both;
          float: right;
          position: relative;
          background: #d8e3ea;
          color: #555555;
          display: inline-block;
          min-width: 30px;
          max-width: 682px;
          word-break: break-all;
          word-wrap: break-word;
          min-height: 22px;
          border-radius: 15px;
          margin-bottom: 20px;
          padding: 6px 8px;
          margin-top: -12px;
        }
        .backImg {
          position: absolute;
          top: 7px;
          right: -4px;
          background: url(./img/corner2.png) no-repeat;
          height: 8px;
          width: 6px;
        }
      }
      .sendNameData {
        .sendUserName {
          float: right;
          margin: 0 20px;
        }
        .time {
          float: left;
          padding: 0 100px;
          margin-top: 16px;
          color: #D8D8D8;
          font-family: '微软雅黑';
          font-size: 12px;
        }
      }
    }
  }
  .loadMore {
    clear: both;
    text-align: center;
    cursor: pointer;
    color: #999;
  }
  .sendMsg {
    clear: both;
    text-align: center;
    padding-top: 50px;
    display: flex;
    margin:0 auto;
    .sendTextArea {
      margin-left: 200px;
    }
  }
}
</style>
